import Vue from 'vue/dist/vue';
import axios from 'axios';
import Swiper, { Pagination, Navigation } from 'swiper';
import lottie from 'lottie-web/build/player/lottie_light';
import OnScreen from 'onscreen';
import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
  // funding slider
  if (document.querySelector('.funding-slider')) {
    const founding = new Splide('.funding-slider', {
      type: 'loop',
      perPage: 5,
      perMove: 1,
      arrows: false,
      pagination: false,
      focus: 'center',
      gap: 15,
      breakpoints: {
        540: {
          perPage: 2,
        },
        640: {
          perPage: 3,
        },
        1024: {
          perPage: 3,
        },
      }
    }).mount();

    founding.on('click', (Slide, e) => {
      founding.go(Slide.index);
    });

    const nextBtn = document.querySelector('.funding-slider__arrow_next');
    const prevBtn = document.querySelector('.funding-slider__arrow_prev');

    nextBtn.addEventListener('click', () => founding.go('>'));
    prevBtn.addEventListener('click', () => founding.go('<'));
  }

  // register slider
  if (document.querySelector('.register-slider')) {
    const register = new Splide('.register-slider', {
      type: 'loop',
      perPage: 7,
      perMove: 1,
      arrows: false,
      pagination: false,
      focus: 'center',
      gap: 15,
      breakpoints: {
        540: {
          perPage: 2,
        },
        768: {
          perPage: 4,
        },
        1024: {
          perPage: 5,
        }
      }
    }).mount();

    register.on('click', (Slide, e) => {
      register.go(Slide.index);
    });

    const nextBtn = document.querySelector('.register-slider__nav_next');
    const prevBtn = document.querySelector('.register-slider__nav_prev');

    nextBtn.addEventListener('click', () => register.go('>'));
    prevBtn.addEventListener('click', () => register.go('<'));
  }
});

Swiper.use([Pagination, Navigation]);

const os = new OnScreen({
  tolerance: 200,
  debounce: 0,
});

if (module.hot) {
  module.hot.accept();
}

const app = new Vue({
  el: '#app',
  data: {
    slider: null,
    mySliderOptions: {
      observer: true,
      slidesPerView: 1, // or 'auto'
      slidesPerColumn: 2,
      grabCursor: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
          slidesPerGroup: 2,
          slidesPerColumn: 1,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
          slidesPerGroup: 3,
          slidesPerColumn: 1,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
          slidesPerGroup: 4,
          slidesPerColumn: 1,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    },
    quotes: [],
    quotesList: [],
    quoteItems: [],
    isOpenNav: false,
    quotesCustom: [
      {
        code: 'EUR/USD',
        s: 3,
        e: 1,
        d: 0
      },
      {
        code: 'GBP/USD',
        s: 3,
        e: 1,
        d: 0
      },
      {
        code: 'USD/CHF',
        s: 3,
        e: 1,
        d: 0
      },
      {
        code: 'USD/JPY',
        s: 3,
        e: 1,
        d: 2
      },
      {
        code: 'Gold',
        s: 2,
        e: 0,
        d: 3
      },
      {
        code: 'Silver',
        s: 3,
        e: 1,
        d: 2
      },
      {
        code: 'WTI',
        s: 2,
        e: 0,
        d: 0
      },
      {
        code: 'Brent',
        s: 2,
        e: 0,
        d: 0
      },
      {
        code: 'BTC/USD',
        s: 4,
        e: 2,
        d: 4
      },
      {
        code: 'ETH/USD',
        s: 2,
        e: 0,
        d: 3
      },
      {
        code: 'LTC/USD',
        s: 2,
        e: 0,
        d: 3
      },
      {
        code: 'XRP/USD',
        s: 3,
        e: 1,
        d: 0
      },
      {
        code: 'Crude Oil',
        s: 2,
        e: 0,
        d: 3
      },
    ]
  },
  methods: {
    getQuotes() {
      axios.get('http://quotes.tradersway.com/bars.php')
        .then((data) => {
          this.quotes = Object.values(data.data);
          for (const quote of this.quotes) {
            if (!this.quotesList.some((x) => x.name === quote.type)) {
              this.quotesList.push({
                name: quote.type,
                active: this.quotesList.some((x) => !!x.active)
              });
            }
          }
          if (this.quotesList.length > 0 && !this.quotesList.some((x) => !!x.active)) {
            this.quotesList[0].active = true;
          }

          for (let qc of this.quotesCustom) {
            Object.assign(qc, data.data[qc.code]);
          }

          this.getQuoteItems();
        })
        .catch((error) => console.log(error));
    },

    switchActiveTab(quote, event) {
      event.target.scrollIntoView({
        block: 'nearest',
        inline: 'center',
        behavior: 'smooth'
      });
      this.quotesList.forEach((q) => q.active = false);
      quote.active = true;
      this.getQuoteItems();
      if (this.slider !== undefined && this.slider !== null) {
        this.slider.slideTo(0);
      }
    },

    getQuoteItems() {
      const code = this.quotesList.find((q) => q.active)?.name;
      this.quoteItems = this.quotesCustom.filter((x) => x.type === code);
    },

    getDigits(item, digits) {
      let l = digits.length;

      if (item.d) {
        digits = digits.slice(0, l - item.d);
        l = l - item.d;
      }

      const a = digits.slice(0, l - item.s);
      const b = digits.slice(l - item.s, l - item.e);
      const c = digits.slice(l - item.e, l);

      return `<div class="quote__digits quote__digits_left">${a}</div><div class="quote__digits quote__digits_center">${b}</div><div class="quote__digits quote__digits_right">${c}</div>`;
    },
  },

  watch: {
    'quotesList': {
      handler: function (val, oldVal) {
        this.getQuoteItems();
      },
      deep: true,
    },
  },

  created() {
    if (document.querySelector('.quotes')) {
      this.getQuotes();

      setInterval(() => {
        this.getQuotes();
      }, 3000);
    }
  },

  mounted() {
    this.slider = new Swiper('.quote__slider', this.mySliderOptions);

    const anim1 = lottie.loadAnimation({
      container: document.getElementById('anim-first'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: 'static/animation/anim1.json', // the path to the animation json
      rendererSettings: {
        scaleMode: 'noScale',
        clearCanvas: false,
        progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
        hideOnTransparent: true //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
      }
    });

    os.on('enter', '#anim-first', (element, event) => {
      anim1.play(); // If animation becomes visible, play it
    });

    const anim2 = lottie.loadAnimation({
      container: document.getElementById('anim-second'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: 'static/animation/anim2.json', // the path to the animation json
      rendererSettings: {
        // context: canvasContext, // the canvas context
        scaleMode: 'noScale',
        clearCanvas: false,
        progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
        hideOnTransparent: true //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
      }
    });

    os.on('enter', '#anim-second', (element, event) => {
      anim2.play(); // If animation becomes visible, play it
    });

    const anim3 = lottie.loadAnimation({
      container: document.getElementById('anim-three'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: 'static/animation/anim3.json', // the path to the animation json
      rendererSettings: {
        scaleMode: 'noScale',
        clearCanvas: false,
        progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
        hideOnTransparent: true //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
      }
    });

    os.on('enter', '#anim-three', (element, event) => {
      anim3.play(); // If animation becomes visible, play it
    });

    const man = lottie.loadAnimation({
      container: document.getElementById('man'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'static/animation/man.json', // the path to the animation json
      rendererSettings: {
        progressiveLoad: false
      },
    });
  }
});

Vue.config.productionTip = false;

// one slide slider
const slider = new Swiper('.slider', {
  slidesPerView: 1,
  loop: true,
  pagination: {
    el: '.slider-pagination',
    type: 'fraction',
  },
  navigation: {
    nextEl: '.slider-button-next',
    prevEl: '.slider-button-prev',
  },
});

// custom select
let x;
let i;
let j;
let l;
let ll;
let selElmnt;
let a;
let b;
let c;
/* Look for any elements with the class "custom-select": */
x = document.getElementsByClassName('select');
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName('select')[0];
  ll = selElmnt.length;
  /* For each element, create a new DIV that will act as the selected item: */
  a = document.createElement('DIV');
  a.setAttribute('class', 'select-selected');
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /* For each element, create a new DIV that will contain the option list: */
  b = document.createElement('DIV');
  b.setAttribute('class', 'select-items select-hide');
  for (j = 1; j < ll; j++) {
    /* For each option in the original select element,
    create a new DIV that will act as an option item: */
    c = document.createElement('DIV');
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener('click', function (e) {
      /* When an item is clicked, update the original select box,
      and the selected item: */
      let y;
      let i;
      let k;
      let s;
      let h;
      let sl;
      let
        yl;
      s = this.parentNode.parentNode.getElementsByTagName('select')[0];
      sl = s.length;
      h = this.parentNode.previousSibling;
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName('same-as-selected');
          yl = y.length;
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute('class');
          }
          this.setAttribute('class', 'same-as-selected');
          break;
        }
      }
      h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener('click', function (e) {
    /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle('select-hide');
    this.classList.toggle('select-arrow-active');
  });
}

function closeAllSelect(elmnt) {
  /* A function that will close all select boxes in the document,
  except the current select box: */
  let x;
  let y;
  let i;
  let xl;
  let yl;
  const
    arrNo = [];
  x = document.getElementsByClassName('select-items');
  y = document.getElementsByClassName('select-selected');
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt === y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove('select-arrow-active');
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add('select-hide');
    }
  }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener('click', closeAllSelect);


const body = document.body

const modals = document.querySelectorAll('[data-modal]')
if (modals) {
  for (const modal of modals) {
    modal.addEventListener('click', (e) => {
      e.preventDefault()
      const attr = modal.getAttribute('data-src')
      if (attr) {
        const modalBox = document.getElementById(attr)
        if (modalBox) {
          modalBox.classList.add('modal_opened')
          if (body) body.classList.add('no-scroll')
        }
      }
    })
  }
}

const closeModals = document.querySelectorAll('[data-modal-close]')
if (closeModals) {
  for (const closeModal of closeModals) {
    closeModal.addEventListener('click', (e) => {
      e.preventDefault()
      const attr = closeModal.getAttribute('data-src')
      if (attr) {
        const modalBox = document.getElementById(attr)
        if (modalBox) {
          modalBox.classList.remove('modal_opened')
          if (body) body.classList.remove('no-scroll')
        }
      }
    })
  }
}


const overlays = document.querySelectorAll('.modal__overlay');
if (overlays) {
  for (const overlay of overlays) {
    overlay.addEventListener('click', (e) => {
      e.preventDefault()
      const allModals = document.querySelectorAll('.modal')
      if (allModals) {
        for (const allModal of allModals) {
          allModal.classList.remove('modal_opened')
          if (body) body.classList.remove('no-scroll')
        }
      }
    })
  }
}

const acc = document.querySelectorAll(".accordion > ul > li > a");
console.log(acc);
for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function(e) {
    e.preventDefault()
    this.classList.toggle("active");
    let panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  });
}
